import { ArchiveService } from '@/modules/archive/archive-service';
import Errors from '@/shared/error/errors';

export const GET_SUBFOLDERS = 'getSubfolders';
export const GET_SUBFOLDERS_FROM_NODE = 'getSubfoldersFromNode';

// mutation types
export const FETCH_SUCCESS = 'fetchSuccess';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';
export const SET_SUBFOLDERS = 'setSubfolders';

const state = {
    rows: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
    subfolders: []
};

const getters = {
    rows: (state) => state.rows,
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
    subfolders: (state) => state.subfolders,
};
const actions = {
    [GET_SUBFOLDERS](context, {payload, id}) {
        context.commit('shared/activateLoading', 'archive/getSubfolders', { root: true });
        const filters = { params: { workspaceRealName: id, ...payload } };
        return new Promise((resolve, reject) => {
            ArchiveService.listSubfolders(filters)
            .then((data) => {
                context.commit(FETCH_SUCCESS, {
                    rows: data.elements,
                    total: data.totalCount,
                });
                resolve();
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'archive/getSubfolders', { root: true });
            });
        });
    },
    [GET_SUBFOLDERS_FROM_NODE](context, {payload, parentFolderId, workspaceRealName}) {
        context.commit('shared/activateLoading', 'archive/getSubfoldersFromNode', { root: true });
        const filters = { params: {
                parentFolderId,
                workspaceRealName,
                ...payload
            }
        };
        return new Promise((resolve, reject) => {
            ArchiveService.listSubfoldersFromNode(filters)
            .then((data) => {
                context.commit(SET_SUBFOLDERS, {
                    rows: data.elements,
                    total: data.totalCount,
                });
                resolve();
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'archive/getSubfoldersFromNode', { root: true });
            });
        });
    },
}
const mutations = {
    [FETCH_SUCCESS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
    [SET_SUBFOLDERS](state, subfolders) {
        state.subfolders = subfolders.rows;
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
