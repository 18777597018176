import { ArchiveService} from '@/modules/archive/archive-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';

// action types
export const DO_CREATE = 'create';
export const DO_UPDATE = 'update';
export const DO_REMOVE = 'remove';

// mutation types

const state = {};

const getters = {};

const mutations = {}

const actions = {
    [DO_CREATE](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'archive/subfoldersForm/create', { root: true });
            ArchiveService.createSubfolder(payload)
                .then((data) => {
                    Message.success(i18n.t('GENERAL.CREATED_SUCCESS'));
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'archive/subfoldersForm/create', { root: true });
                });
        });
    },
    [DO_REMOVE](context, subfolder) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'archive/subfoldersForm/remove', { root: true });
            ArchiveService.removeSubfolder(subfolder)
                .then(() => {
                    Message.success(i18n.t('GENERAL.REMOVE_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'archive/subfoldersForm/remove', { root: true });
                });
        });
    },
    [DO_UPDATE](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'archive/subfoldersForm/update', { root: true });
            ArchiveService.updateSubfolder(payload)
                .then(() => {
                    Message.success(i18n.t('GENERAL.UPDATED_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'archive/subfoldersForm/update', { root: true });
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
