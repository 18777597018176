// import dashboardStore from '@/modules/archive/store/dashboard-store';
import workspacesStore from '@/modules/archive/store/workspaces-store';
import workspacesFormStore from '@/modules/archive/store/workspaces-form-store';
import subfolderStore from '@/modules/archive/store/subfolders-store';
import subfolderFormStore from '@/modules/archive/store/subfolders-form-store';
import documentsStore from '@/modules/archive/store/documents-store';
import documentStore from '@/modules/archive/store/document-store';
import { ArchivePermissions } from '@/modules/archive/archive-permissions';

const getters = {
    archivePermissions : (state, getters, rootState, rootGetters) => {
        const currentUser = rootGetters['auth/currentUser'];
        return {
            hasPermissionToEdit: new ArchivePermissions(currentUser).admin,
            hasPermissionToDestroy: new ArchivePermissions(currentUser).admin,
        }
    }
};

export default {
    namespaced: true,

    modules: {
        // dashboard: dashboardStore,
        workspaces: workspacesStore,
        workspacesForm: workspacesFormStore,
        subfolders: subfolderStore,
        subfoldersForm: subfolderFormStore,
        documents: documentsStore,
        document: documentStore,
    },
    getters,
};
