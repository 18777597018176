import PermissionChecker from '@/security/permission-checker';
import Permissions from '@/security/permissions';

export class ArchivePermissions {
    constructor(currentUser) {
        const permissionChecker = new PermissionChecker(
            currentUser,
        );

        this.read = permissionChecker.match(
            Permissions.values.archiveRead,
        );
        this.create = permissionChecker.match(
            Permissions.values.archiveCreate,
        );
        this.admin = permissionChecker.match(
            Permissions.values.archiveAdmin,
        );
    }
}
