import { ArchiveService} from '@/modules/archive/archive-service';
import Errors from '@/shared/error/errors';

// action types
export const GET_DOCUMENT = 'getDocument';
export const DOWNLOAD_DOCUMENT = 'downloadDocumentAttachment';
export const GET_FOLDER_TYPE_BY_DOCUMENT_CODE = 'getFolderTypeByDocumentCode';
export const DO_UPLOAD = 'upload';
export const DO_MANUAL_ARCHIVE = 'manualArchive';
export const MANUAL_UPLOAD_SETTINGS = 'manualUploadSettings';
export const GET_ARCHIVE_DOC_TYPE = 'getAllArchiveDocumentsTypes';

// mutation types
export const SET_ARCHIVED_DOCUMENT = 'setArchivedDocument';
export const SET_MANUAL_UPLOAD_HEADER = 'setManualUploadHeader';

const state = {
    archivedDocument: {},
    manualUploadSettings: {
        uploadFileToGUArchive: false,
        manualArchive: false
    }
};

const getters = {
    archivedDocument: (state) => state.archivedDocument,
    manualUploadSettings: (state) => state.manualUploadSettings,
};

const mutations = {
    [SET_ARCHIVED_DOCUMENT](state, archivedDocument) {
        state.archivedDocument = { ...state.archivedDocument, ...archivedDocument };
    },
    [SET_MANUAL_UPLOAD_HEADER](state, status) {
        state.manualUploadSettings = status;
    },
};

const actions = {
    async [GET_DOCUMENT](context, {id, workspace}) {
        context.commit('shared/activateLoading', 'archive/document/getArchivedDocument', { root: true });
        try {
            const filters = {
                params: {
                    archivedDocumentId: id,
                    workspaceRealName: workspace.realName,
                }
            };
            const docInfo = await ArchiveService.getArchivedDocument(filters);
            context.commit(SET_ARCHIVED_DOCUMENT, {documentInfo: docInfo});

            const docMetadata = await ArchiveService.getArchivedDocumentMetadata(filters);
            context.commit(SET_ARCHIVED_DOCUMENT, {docMetadata});

            const docAttachment = await ArchiveService.getArchivedDocumentAttachments(filters);
            context.commit(SET_ARCHIVED_DOCUMENT, {docAttachment});

        } catch (error) {
            Errors.handle(error);
        } finally {
            context.commit('shared/deactivateLoading', 'archive/document/getArchivedDocument', { root: true });
        }
    },

    [DOWNLOAD_DOCUMENT](context, params) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'archive/document/downloadDocumentAttachment', { root: true });
            ArchiveService.downloadDocumentAttachment({params})
                .then((data) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'archive/document/downloadDocumentAttachment', { root: true });
                });
        });
    },
    [GET_FOLDER_TYPE_BY_DOCUMENT_CODE](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'archive/document/getFolderTypeByDocumentCode', { root: true });
            ArchiveService.getFolderTypeByDocumentCode(id)
                .then((data) => {
                    resolve(data.data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'archive/document/getFolderTypeByDocumentCode', { root: true });
                });
        });
    },
    async [DO_UPLOAD](context, files) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'archive/document/upload', { root: true });
            context.commit(SET_MANUAL_UPLOAD_HEADER, {uploadFileToGUArchive: true, manualArchive: false});
            ArchiveService.upload(files)
                .then((data) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'archive/document/upload', { root: true });
                    context.commit(SET_MANUAL_UPLOAD_HEADER, {uploadFileToGUArchive: false, manualArchive: false});
                });
        });
    },
    [DO_MANUAL_ARCHIVE](context, params) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'archive/document/archiveDocument', { root: true });
            context.commit(SET_MANUAL_UPLOAD_HEADER, {uploadFileToGUArchive: false, manualArchive: true});
            ArchiveService.archiveDocument(params)
                .then((data) => {
                    resolve(data.data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'archive/document/archiveDocument', { root: true });
                    context.commit(SET_MANUAL_UPLOAD_HEADER, {uploadFileToGUArchive: false, manualArchive: false});
                });
        });
    },
    [MANUAL_UPLOAD_SETTINGS](context, status) {
        context.commit(SET_MANUAL_UPLOAD_HEADER, status);
    },
    [GET_ARCHIVE_DOC_TYPE](context) {
        return new Promise((resolve, reject) => {
            context.dispatch('setMenu', 'archive', { root: true });
            ArchiveService.getAllArchiveDocumentsTypes()
            .then((data) => {
                resolve(data.data)
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.dispatch('setMenu', 'default', { root: true });
            });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
