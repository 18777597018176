import { ArchiveService } from '@/modules/archive/archive-service';
import Errors from '@/shared/error/errors';
import moment from 'moment';

export const GET_DOCUMENTS = 'getDocuments';
export const GET_EXPIRING_DOCUMENTS = 'getExpiringDocuments';
export const CLEAR_SORT = 'clearSort';

// mutation types
export const FETCH_SUCCESS = 'fetchSuccess';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';
export const SET_SORT = 'setSort';
export const SET_EXPIRING_COUNT_MONTHLY = 'setExpiringCountMonthly';
export const SET_EXPIRING_COUNT_WEEKLY = 'setExpiringCountWeekly';

const state = {
    rows: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
    storeSort: {sortBy: '', sortDesc: false},
    expiringCountMonthly: 0,
    expiringCountWeekly: 0,
};

const getters = {
    rows: (state) => state.rows,
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
    sorting: (state) => state.storeSort,
    expiringCountMonthly: (state) => state.expiringCountMonthly,
    expiringCountWeekly: (state) => state.expiringCountWeekly,
};
const actions = {
    [GET_DOCUMENTS](context, {payload, workspaceId, folderId}) {
        context.commit('shared/activateLoading', 'archive/getDocuments', { root: true });
        let filters = { params:
            {
                folderId,
                workspaceRealName: workspaceId,
                ...payload
            } };
        if (payload && !payload?.sortField) {
            const sortingParams = {
                ...payload,
                isAscendingOrder: false,
                sortField: 'documentCreatedDate'
            }

            filters = { params:
                {
                    folderId,
                    workspaceRealName: workspaceId,
                    ...sortingParams
                }
            };
        }

        return new Promise((resolve, reject) => {
            ArchiveService.listDocuments(filters)
            .then((data) => {
                context.commit(FETCH_SUCCESS, {
                    rows: data.items,
                    total: data.totalItems,
                });
                resolve();
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'archive/getDocuments', { root: true });
            });
        });
    },
    [GET_EXPIRING_DOCUMENTS](context) {
        return new Promise((resolve, reject) => {
            const paramsMontly = {
                startDate: moment().subtract(30, 'days').toDate(),
                endDate: moment().toDate(),
            } 
            ArchiveService.listExpiringDocuments(paramsMontly)
            .then((data) => {
                context.commit(SET_EXPIRING_COUNT_MONTHLY, data?.length)
                resolve();
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'archive/getExpiringDocuments', { root: true });
            });

            const paramsWeekly = {
                startDate: moment().subtract(7, 'days').toDate(),
                endDate: moment().toDate(),
            } 
            ArchiveService.listExpiringDocuments(paramsWeekly)
            .then((data) => {
                context.commit(SET_EXPIRING_COUNT_WEEKLY, data?.length)
                resolve();
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'archive/getExpiringDocuments', { root: true });
            });
        });
    },
    [CLEAR_SORT](context) {
        context.commit(SET_SORT, {sortBy: '', sortDesc: false});
    }
}
const mutations = {
    [FETCH_SUCCESS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
    [SET_SORT](state, value) {
        state.storeSort = value;
    },
    [SET_EXPIRING_COUNT_MONTHLY](state, value) {
        state.expiringCountMonthly = value;
    },
    [SET_EXPIRING_COUNT_WEEKLY](state, value) {
        state.expiringCountWeekly = value;
    }
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
