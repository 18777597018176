import { ArchiveService } from '@/modules/archive/archive-service';
import Errors from '@/shared/error/errors';

export const GET_WORKSPACES = 'getWorkspaces';
export const FILTER_STORAGE = 'filterStorage';
export const DO_SET_ACHIVE_STATUS = 'setArchiveStatus';
export const DO_UPDATE_BREADCRUMBS = 'updateBreadcrumbs';
export const CLEAR_SORT = 'clearWorkspacesSort';

// mutation types
export const FETCH_SUCCESS = 'fetchSuccess';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';
export const SET_ARCHIVE = 'setArchive';
export const SET_BREADCRUMBS = 'setBreadcrumbs';
export const SET_PERIOD_REPORT = 'setPeriodReport';
export const SET_SORT = 'setSort';

const state = {
    rows: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
    archiveCheck: true,
    periodReport: [],
    breadcrumbs: [],
    storeSort: {sortBy: '', sortDesc: false},
};

const getters = {
    rows: (state) => state.rows,
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
    archiveCheck: (state) => state.archiveCheck,
    breadcrumbs: (state) => state.breadcrumbs,
    periodReport: (state) => state.periodReport,
    sorting: (state) => state.storeSort,
};
const actions = {
    [GET_WORKSPACES](context, payload) {
        context.commit('shared/activateLoading', 'archive/getWokspaces', { root: true });
        const filters = { params: {
            sortField: 'realName',
            isAscendingOrder: true,
            ...payload
            }
        };
        return new Promise((resolve, reject) => {
            ArchiveService.listWorkspaces(filters)
            .then((data) => {
                context.commit(FETCH_SUCCESS, {
                    rows: data.elements,
                    total: data.totalCount,
                });
                resolve();
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'archive/getWokspaces', { root: true });
            });
        });
    },
    [FILTER_STORAGE](context, payload) {
        context.commit('shared/activateLoading', 'archive/filterStorage', { root: true });
        return new Promise((resolve, reject) => {
            ArchiveService.filterStorage(payload)
            .then((data) => {
                context.commit(SET_PERIOD_REPORT, data.data);
                resolve();
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'archive/filterStorage', { root: true });
            });
        });
    },
    [DO_SET_ACHIVE_STATUS] (context, payload){
        context.commit(SET_ARCHIVE, payload);
    },
    [DO_UPDATE_BREADCRUMBS] (context, payload) {
        context.commit(SET_BREADCRUMBS, payload);
    },
    [CLEAR_SORT](context) {
        context.commit(SET_SORT, {sortBy: '', sortDesc: false});
    }
}
const mutations = {
    [FETCH_SUCCESS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
    [SET_ARCHIVE](state, archive) {
        state.archiveCheck = archive;
    },
    [SET_BREADCRUMBS](state, breadcrumbs) {
        state.breadcrumbs = breadcrumbs;
    },
    [SET_PERIOD_REPORT](state, periodReport) {
        state.periodReport = periodReport;
    },
    [SET_SORT](state, value) {
        state.storeSort = value;
    },

};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
